.form-group {
    margin-bottom: 30px;
}

.form-group input,
.form-group textarea {
    width: 100%;
    height: 45px;
    background: rgba(var(--white), 1);
    border: 1px solid rgba(var(--grey), 0.8);
    font-weight: 400;
    font-size: 15px;
    border-radius: 10px;
    padding: 6px 18px;
    box-sizing: border-box;
    color: var(--text-color);
    margin-bottom: 0;
}

.form-group textarea {
    height: 125px;
}

.form-group .errorMessage {
    width: 100%;
    float: left;
    z-index: 5;
    color: red;
    font-size: 12px;
    line-height: 1em;
    margin-top: 5px;
}