.ad-footer {
    width: 100%;
    display: inline-block;
    padding: 20px 0 7px 0;
}

.ad-footer .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.ad-footer p,
.ad-footer p a {
    color: rgba(var(--white), 1);
}

.ad-footer aside {
    width: 30%;
}

.ad-footer aside {
    width: 23%;
}

.ad-footer aside ul {
    margin-bottom: 0;
}

.ad-footer aside ul li {
    font-size: 17px;
    margin-bottom: 5px;
}

.ad-footer aside ul li a svg {
    width: 17px;
    height: 17px;
    fill: rgba(var(--white), 1);
}

.ad-footer aside h5,
.ad-footer aside ul li a {
    color: rgba(var(--white), 1);

}

.ad-footer aside:first-child {
    width: 31%;
    padding-right: 80px;
}

.ad-footer .footer-logo {
    width: 100%;
}

.ad-footer .footer-logo img {
    max-height: 38px;
}

.ad-social-links {
    display: flex;
    margin-bottom: 0;
}

.ad-social-links li {
    display: inline-block;
    vertical-align: mathematical;
}

.ad-social-links li:nth-child(2) a svg {
    width: 16px;
    height: 16px;
}

.ad-social-links a {
    margin:0 30px 0 0 !important
}

.ad-social-links li a svg {
    fill: rgba(var(--white), 1) !important;
    margin-right: 0 !important;
    width: 18px;
    height: 18px;
}

@media (max-width: 991px) {
    .ad-footer .container {
        display: flex;
        flex-wrap: wrap;
    }

    .ad-footer aside {
        width: 33.33%;
    }

    .ad-footer aside:first-child {
        width: 100%;
        margin-bottom: 45px;
    }
}

@media (max-width: 991px) {
    .ad-footer {
        padding: 35px 0 25px 0;
    }
    .ad-footer aside {
        width: 100%;
        margin-bottom: 45px;
    }

    .ad-footer aside:last-child {
        margin-bottom: 0;
    }

    .ad-social-links {
        display: flex;
        justify-content: center;
        margin-bottom: 35px;
    }

    .ad-social-links a {
        margin:0 10px !important
    }
}