.ad-hero-home {
    position: relative;
    display: flex;
}

.ad-hero-home video {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    margin: 0;
}

.ad-hero-home:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(var(--black), 0.6);
    z-index: 35;
}

.ad-hero-text {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 45;
    text-align: center;
}

.ad-icon-center svg {
    width: 65px;
    height: 65px;
}

.text-count {
    font-size: 50px;
    line-height: 1.2em;
}

.ad-products a:hover .icon-play {
    transform: translateX(-50%) translateY(-50%);
    opacity: 1;
}

.ad-partners figure {
    display: flex;
    justify-content: center;
}

.ad-partners figure img {
    max-height: 30px;
    min-height: 30px;
}

.ad-partners .slick-dots {
    left: 0;
    bottom: -40px;
}

.ad-partners .slick-dots li {
    margin: 0 2px;
}

.ad-partners .slick-dots li button:before {
    font-size: 15px;
}

.ad-process article {
    height: 100%;
    border-left: 1px solid rgba(var(--primary-color), 1);
    counter-reset: list-number;
}

.ad-process aside {
    position: relative;
    padding-left: 80px;
    margin-bottom: 120px;
    top: -10px
}

.ad-process aside:last-child {
    margin-bottom: 0;
    padding-bottom: 0
}

.ad-process aside:before {
    counter-increment: list-number;
    content: counter(list-number);
    width:60px;
    height:60px;
    display:inline-flex;
    align-items:center;
    justify-content: center;
    font-size:26px;
    background-color:rgba(var(--primary-color), 1);
    border-radius:50%;
    color: rgba(var(--white), 1) !important;
    position: absolute;
    left: -30px;
    top: -5px;
}